import axios from 'axios';
import store from '@/store';
import {encryptPassword} from '@/services/utils';

export const textmuseClient = axios.create({});

textmuseClient.interceptors.request.use((config) => {
  const token = store.getters.getAccessToken;
  if (token) {
    config.headers['X-Token'] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const loginWithUsernamePassword = async (username, password) => {
  return textmuseClient.post('/internal/v1/users/login', {
    username,
    password: await encryptPassword(password),
  });
};

export const changeUserPassword = async (password) => {
  return textmuseClient.put('/internal/v1/users/password', {
    password: await encryptPassword(password),
  });
};

export const createTextTemplate = (template) => {
  return textmuseClient.post('/internal/v1/text-templates', template);
};

export const updateTextTemplate = (id, template) => {
  return textmuseClient.put(`/internal/v1/text-templates/${id}`, template);
};

export const searchTextTemplate = (query, offset, limit) => {
  return textmuseClient.get('/internal/v1/text-templates/', {
    params: {
      keyword: query,
      offset: offset,
      limit: limit,
    },
  });
};

export const deleteTextTemplate = (id) => {
  return textmuseClient.delete(`/internal/v1/text-templates/${id}`);
};

// 创建prompt
export const createPrompt = (prompt) => {
  return textmuseClient.post('/internal/v1/prompts', prompt);
};

// 获取prompt
export const searchPrompt = (query, offset, limit) => {
  return textmuseClient.get('/internal/v1/prompts/', {
    params: {
      keyword: query,
      offset: offset,
      limit: limit,
    },
  });
};

// 删除prompt
export const deletePrompt = (id) => {
  return textmuseClient.delete(`/internal/v1/prompts/${id}`);
};

// 更新prompt
export const updatePrompt = (id, prompt) => {
  return textmuseClient.put(`/internal/v1/prompts/${id}`, prompt);
};

export const searchUserProject = (query, offset, limit) => {
  return textmuseClient.get('/internal/v1/users/projects/', {
    params: {
      keyword: query,
      offset: offset,
      limit: limit,
    },
  });
};

export const debugTextGen = (
    promptId, projectUUID, textTemplates, textTemplateTags) => {
  return textmuseClient.post('/internal/v1/textgen/debug', {
    prompt_id: promptId,
    project_uuid: projectUUID,
    text_templates: textTemplates,
    text_template_tags: textTemplateTags,
  });
};

export const structureTextGen = (projectUUID, num, category) => {
  return textmuseClient.post('/internal/v1/textgen/structure', {
    project_uuid: projectUUID,
    num_templates: num,
    category: category,
  });
};

export const debugTextGenAgentic = (
    writePromptId,
    reviewPromptId,
    revisePromptId,
    projectUUID,
    textTemplates,
    textTemplateTags) => {
  return textmuseClient.post('/internal/v1/textgen/debug/agentic', {
    write_prompt_id: writePromptId,
    review_prompt_id: reviewPromptId,
    revise_prompt_id: revisePromptId,
    project_uuid: projectUUID,
    text_templates: textTemplates,
    text_template_tags: textTemplateTags,
  });
};

export const createQuestion = (question) => {
  return textmuseClient.post('/internal/v1/questions/', question);
};

export const searchQuestion = (query, onlyEnabled, offset, limit) => {
  return textmuseClient.get('/internal/v1/questions/', {
    params: {
      keyword: query,
      only_enabled: onlyEnabled,
      offset: offset,
      limit: limit,
    },
  });
};

export const deleteQuestion = (id) => {
  return textmuseClient.delete(`/internal/v1/questions/${id}`);
};

export const updateQuestion = (id, question) => {
  return textmuseClient.put(`/internal/v1/questions/${id}`, question);
};

export const getQuestion = (id) => {
  return textmuseClient.get(`/internal/v1/questions/${id}`);
};

export const updateUserProject = (id, project) => {
  return textmuseClient.put(`/internal/v1/users/projects/${id}`, project);
};

export const createIndustry = (industry) => {
  return textmuseClient.post('/internal/v1/industries', industry);
};

export const searchIndustries = (query) => {
  return textmuseClient.get('/internal/v1/industries/', {
    params: {
      keyword: query,
    },
  });
};

export const getIndustryById = (id) => {
  return textmuseClient.get(`/internal/v1/industries/${id}`);
};

export const updateIndustry = (id, industry) => {
  return textmuseClient.put(`/internal/v1/industries/${id}`, industry);
};

export const deleteIndustry = (id) => {
  return textmuseClient.delete(`/internal/v1/industries/${id}`);
};

export const bindQuizQuestions = (industryId, questions) => {
  return textmuseClient.post(`/internal/v1/quizzes/bindings/${industryId}`,
      questions);
};

export const getBindQuizQuestions = (industryId) => {
  return textmuseClient.get(`/internal/v1/quizzes/bindings/${industryId}`);
};

export const getTextgenDebugTaskStatus = (taskId) => {
  return textmuseClient.get(`/internal/v1/textgen/debug/tasks/${taskId}`);
};

export const getAllUsers = async (keyword, offset, limit) => {
  return textmuseClient.get('/internal/v1/users', {
    params: {
      keyword,
      offset,
      limit,
    },
  });
};

export const getUserCreditHistory = async (secUID) => {
  return textmuseClient.get(`/internal/v1/users/${secUID}/credits/history`, {
    params: {},
  });
};

export const bonusUserCredit = async (secUID, amount, reason, comment) => {
  return textmuseClient.put(`/internal/v1/users/${secUID}/credits/bonus`, {
    amount: amount,
    reason: reason,
    comment: comment,
  });
};

export const getPackages = async (keyword, display) => {
  return textmuseClient.get('/internal/v1/packages', {
    params: {
      keyword: keyword,
      display: display,
    },
  });
};

export const createPackage = async (pkg) => {
  return textmuseClient.post('/internal/v1/packages', pkg);
};

export const updatePackage = async (id, pkg) => {
  return textmuseClient.put(`/internal/v1/packages/${id}`, pkg);
};

export const deletePackage = async (id) => {
  return textmuseClient.delete(`/internal/v1/packages/${id}`);
};

export const getIndustryQuizSchema = async (industryId) => {
  return textmuseClient.get(`/internal/v1/quizzes/bindings/${industryId}`);
};

export const getAppConfig = async () => {
  return textmuseClient.get(`/internal/v1/settings`);
};

export const uploadTextTemplateFile = async (content) => {
  const formData = new FormData();
  formData.append('file', content);
  return textmuseClient.post(`/internal/v1/text-template/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',

    },
  });
};
