<template>
  <div style="width: 90%;">
    <el-checkbox-group v-model="selectedValues" @change="updateValue">
      <el-row v-for="option in options" :key="option" class="checkbox-with-description" :gutter="20">
        <el-col :span="5">
          <el-checkbox :label="option.split('/')[0]" :value="option.split('/')[0]">{{ option.split("/")[0] }}</el-checkbox>
        </el-col>
        <el-col :span="19">
          <el-input
              :autosize="{ minRows: 1, maxRows: 4 }"
              type="textarea"
              v-if="selectedValues.indexOf(option.split('/')[0]) > -1"
              v-model="descriptions[option.split('/')[0]]"
              :placeholder="option.split('/')[1]"
              @change="updateValue"></el-input>
        </el-col>
      </el-row>
    </el-checkbox-group>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, ref, watch} from 'vue';

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);
const selectedValues = ref({});
const descriptions = ref({});
const updateValue = () => {
  const updatedValue = selectedValues.value.map(value => ({
    value,
    description: descriptions.value[value] || '',
  }));
  emit('update:modelValue', updatedValue);
};

const initValue = (newValue) => {
  selectedValues.value = newValue.map(item => item.value.split('/')[0]);
  descriptions.value = Object();
  Object.assign(descriptions.value, newValue.reduce((obj, item) => {
    obj[item.value.split('/')[0]] = item.description;
    return obj;
  }, {}));
}

watch(() => props.modelValue, (newValue) => {
  initValue(newValue);
});

initValue(props.modelValue);

</script>

<style scoped>
.checkbox-with-description {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-with-description .el-input {
  margin-left: 10px;
  flex-grow: 1;
}
</style>
