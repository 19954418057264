<template>
  <section>

    <el-row class="breadcrumb">
      <el-col>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="$router.resolve({name: 'UserManagement'}).path">用户管理</el-breadcrumb-item>
          <el-breadcrumb-item>支付记录</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <el-row align="middle" justify="space-between" :gutter="20" class="main-top">
      <el-col :span="6">
        <el-input placeholder="搜索" v-model="searchQuery" class="search-input"
                  size="large"
                  @keyup.enter="listUserCredits"></el-input>
      </el-col>
      <el-col :span="2">
        <el-button @click="topUp" size="large" type="primary">充值</el-button>
      </el-col>
    </el-row>

    <el-table :data="credits">
      <el-table-column label="标题" width="180">
        <template v-slot="{ row }">
          {{ row.title }}
        </template>
      </el-table-column>
      <el-table-column label="购买的拍币" width="180">
        <template v-slot="{ row }">
          {{ row.paid_credits }}
        </template>
      </el-table-column>
      <el-table-column label="奖励的拍币">
        <template v-slot="{ row }">
          {{ row.bonus_credits }}
        </template>
      </el-table-column>
      <el-table-column label="本次购买 +/-">
        <template v-slot="{ row }">
          {{ row.paid_changed }}
        </template>
      </el-table-column>
      <el-table-column label="本次奖励 +/-">
        <template v-slot="{ row }">
          {{ row.bonus_changed }}
        </template>
      </el-table-column>
      <el-table-column label="奖励发放人">
        <template v-slot="{ row }">
          <span v-if="row.admin_bonus_record">
            {{ row.admin_bonus_record.creator.username }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="原因">
        <template v-slot="{ row }">

          <el-text truncated v-if="row.admin_bonus_record">
            {{ row.admin_bonus_record.reason }}
          </el-text>

        </template>
      </el-table-column>
      <el-table-column label="备注">
        <template v-slot="{ row }">
          <el-text truncated>
            {{ row.comment }}
          </el-text>
        </template>
      </el-table-column>
      <el-table-column label="时间">
        <template v-slot="{ row }">
          {{ timeAgo(row.created_at) }}
        </template>
      </el-table-column>
    </el-table>
    <BonusUserDialog
        v-model:dialogVisible="dialogVisible"
        :user-sec-uid="secUid"
    />
  </section>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import {getUserCreditHistory} from '@/services/textmuse';
import BonusUserDialog from '@/components/BonusUserDialog.vue';

const route = useRoute();
const secUid = ref(route.params.sec_uid);
const credits = ref([]);
const searchQuery = ref('');
const dialogVisible = ref(false);

const listUserCredits = async () => {
  const resp = await getUserCreditHistory(secUid.value);
  credits.value = resp.data.history;
};

const topUp = () => {
  dialogVisible.value = true;
};

watch(dialogVisible, (newValue, oldValue) => {
  if (oldValue === true && newValue === false) {
    listUserCredits();
  }
});

onMounted(() => {
  listUserCredits();
});
</script>

<style>
.breadcrumb {
  margin-bottom: .8rem;
}
</style>
