<template>
  <el-dialog title="添加问题" :model-value="dialogVisible"
             class="industry-quiz-editing-dialog"
             @update:modelValue="val => $emit('update:dialogVisible', val)">
    <el-form>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="行业">
            <el-select
                v-model="chosenIndustry"
                filterable
                remote
                placeholder="请选择行业"
                :remote-method="searchIndustries"
                :disabled="editIndustryId !== null"
            >
              <el-option
                  v-for="item in foundIndustries"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-for="binding in quizBindings" :key="binding.order">
        <el-col :span="3">
          <el-form-item>
            <el-button small @click="removeBinding(binding.id)" text bg type="danger">
              <el-icon>
                <Delete/>
              </el-icon>
            </el-button>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-checkbox v-model="binding.required" label="必选" />
        </el-col>
        <el-col :span="3">
          <el-input v-model="binding.page" placeholder="页码" />
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <el-select
                v-model="binding.id"
                filterable
                remote
                placeholder="请选择问题"
                :remote-method="searchQuestions"
            >
              <el-option
                  v-for="item in foundQuestions"
                  :key="item.id"
                  :label="item.display_name"
                  :value="item.id"
                  :disabled="quizBindings.findIndex(binding => binding.id === item.id) > -1"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item>
            <el-button @click="moveUp(binding.id)" type="success" text bg>
              <el-icon>
                <ArrowUp/>
              </el-icon>
            </el-button>
          </el-form-item>
        </el-col>
        <el-col :span="2">
          <el-form-item>
            <el-button @click="moveDown(binding.id)" type="primary" text bg>
              <el-icon>
                <ArrowDown/>
              </el-icon>
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>


      <el-form-item>
        <el-button @click="addBindingSlot" style="width: 100%" text bg type="primary">
          <el-icon>
            <Plus/>
          </el-icon>
        </el-button>
      </el-form-item>

    </el-form>
    <el-row>
      <el-col :offset="11" :span="2">
        <el-button type="danger" @click="createOrUpdateQuiz">
      <span v-if="editIndustryId">
         更新
      </span>
          <span v-else>
        创建
      </span>
        </el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>
<script setup>

import {defineEmits, defineProps, ref, watch} from 'vue';
import * as textmuse_api from '@/services/textmuse';
import {ElMessageBox, ElNotification} from 'element-plus';
import {prettifyResponseError} from '@/services/utils';

const props = defineProps({
  dialogVisible: Boolean,
  editIndustryId: null,
});

const foundQuestions = ref([]);
const foundIndustries = ref([]);
const chosenIndustry = ref(null);
const quizBindings = ref([{order: 1, id: null, required: false}]);

const emit = defineEmits(['update:dialogVisible']);

const closeDialog = () => {
  emit('update:dialogVisible', false);
};

const searchQuestions = (query) => {
  textmuse_api.searchQuestion(query, true, 0, 100).then(response => {
    foundQuestions.value = response.data;
  });
};

const addBindingSlot = () => {
  const nextId = quizBindings.value.length > 0 ? quizBindings.value[quizBindings.value.length - 1].order + 1 : 1;
  quizBindings.value.push({order: nextId, page: 1, id: null, required: true});
};

const removeBinding = (value) => {
  const index = quizBindings.value.findIndex(binding => binding.id === value);
  quizBindings.value.splice(index, 1);
};

const moveUp = (value) => {
  const index = quizBindings.value.findIndex(binding => binding.id === value);
  if (index > 0) {
    const temp = quizBindings.value[index - 1].order;
    quizBindings.value[index - 1].order = quizBindings.value[index].order;
    quizBindings.value[index].order = temp;
    quizBindings.value.sort((a, b) => a.order - b.order);
    searchQuestions();
  }
};

const moveDown = (value) => {
  const index = quizBindings.value.findIndex(binding => binding.id === value);
  if (index < quizBindings.value.length - 1) {
    const temp = quizBindings.value[index + 1].order;
    quizBindings.value[index + 1].order = quizBindings.value[index].order;
    quizBindings.value[index].order = temp;
    quizBindings.value.sort((a, b) => a.order - b.order);
    searchQuestions();
  }
};

const searchIndustries = (query) => {
  textmuse_api.searchIndustries(query).then(response => {
    foundIndustries.value = response.data;
  });
};

const createOrUpdateQuiz = () => {
  const data = {
    questions: quizBindings.value.filter(x => x.id !== null).map(x => {
      return {
        id: x.id,
        position: x.order + x.page * 10000,
        required: x.required,
      };
    }),
  };
  console.log(data);
  textmuse_api.bindQuizQuestions(chosenIndustry.value, data).then(() => {
    closeDialog();
    ElNotification({
      message: '创建成功',
      type: 'success',
    });
  }).catch((error) => {
    ElMessageBox.alert(prettifyResponseError(error), '创建失败', {
      confirmButtonText: '确定',
      type: 'error',
    });
  });
};

watch(() => props.dialogVisible, () => {
  if (props.editIndustryId !== null) {
    searchQuestions();
    searchIndustries();
    textmuse_api.getIndustryById(props.editIndustryId).then((response) => {
      chosenIndustry.value = response.data.id;
    });
    textmuse_api.getBindQuizQuestions(props.editIndustryId).then((response) => {
      console.log(response.data);
      quizBindings.value = response.data.quiz_questions.map((x) => {
        return {
          page: Math.floor(x.position / 10000),
          order: x.position % 10000,
          id: x.question.id,
          required: x.required
        };
      });
      quizBindings.value.sort((a, b) => a.order - b.order);
    });
  } else {
    quizBindings.value = [{order: 1, page: 1, id: null, required: false}];
    chosenIndustry.value = null;
  }
});

</script>
<style scoped>

</style>
